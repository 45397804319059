<template>
  <div id="main" class="hero is-fullheight">
    <div class="hero-body" style="padding: 0">
      <div class="jumbo-parallax">
        <div class="jumbo-overlay has-text-centered">
          <simsed-logo width="64" font-size="4em" />
          <!-- <p class="jumbo-app-name">SIMSED</p> -->
          <p class="jumbo-app-description">
            school information management application
          </p>
          <div class="mt-5" style="margin-bottom: 80px">
            <router-link
              to="/register"
              class="button is-success is-rounded is-outlined is-medium"
              >Get Started free for 60days</router-link
            >
          </div>
        </div>
      </div>
    </div>
    <div class="container">
      <div class="hero-body" style="margin-top: 40px">
        <div class="container has-text-centered">
          <h1 class="simsed-description">
            School Management System<br />
            designed for schools to provide a <br />
            simplified, functional, scalable and secure application plaform.
          </h1>
        </div>
      </div>
      <div class="has-text-centered students mb-5">
        <img
          src="../assets/images/students.png"
          alt="students"
          width="500"
          height="auto"
        />
      </div>
      <div class="has-text-centered" style="margin-bottom: 80px">
        <router-link
          to="/register"
          class="button is-success is-rounded is-outlined is-medium"
          >Get Started free for 60days</router-link
        >
      </div>
    </div>
    <div class="container-fluid" style="margin-bottom: 80px">
      <div class="columns is-tablet is-centered">
        <div class="column is-8">
          <div class="columns is-multiline is-tablet">
            <div class="column is-12 has-text-centered">
              <h3 class="simsed-building-blocks">Simsed Building Blocks</h3>
            </div>
            <div class="column is-half-tablet">
              <div class="app-info has-background-light p-4">
                <div class="has-text-centered mt-5">
                  <fa-icon icon="smile" class="fa-3x" style="color: #0ba5ec" />
                </div>
                <h2 class="title is-4 has-text-centered mt-3">SIMPLE</h2>
                <p>
                  SIMSED is easy to use and without unnecessary complexities.
                  The core of the application is built mainly on the most
                  important processes of school operations. This simplification
                  ensures expansion based on features needed by the school.
                </p>
              </div>
            </div>
            <div class="column is-half-tablet">
              <div class="app-info has-background-light p-4">
                <div class="has-text-centered mt-5">
                  <fa-icon
                    icon="palette"
                    class="fa-3x"
                    style="color: #0ba5ec"
                  />
                </div>
                <h2 class="title is-4 has-text-centered mt-3">FUNCTIONAL</h2>
                <p>
                  SIMSED is designed to enable schools have an initial
                  functional School Information Management Solution (SIMS) with
                  a small footprint. This creates a flexible platform that is
                  approachable for their operational needs and utilization.
                </p>
              </div>
            </div>
            <div class="column is-half-tablet">
              <div class="app-info has-background-light p-4">
                <div class="has-text-centered mt-5">
                  <fa-icon icon="cubes" class="fa-3x" style="color: #0ba5ec" />
                </div>
                <h2 class="title is-4 has-text-centered mt-3">SCALABLE</h2>
                <p>
                  SIMSED is a Software-as-as-Service (SaaS) that is powered by
                  Amazon Web Service's (AWS) industry proven cloud
                  Infrastructure-as-a-Service (IaaS). The application is built
                  to be scalable, stable and responsive under heavy workload.
                </p>
              </div>
            </div>
            <div class="column is-half-tablet">
              <div class="app-info has-background-light p-4">
                <div class="has-text-centered mt-5">
                  <fa-icon
                    icon="shield-alt"
                    class="fa-3x"
                    style="color: #0ba5ec"
                  />
                </div>
                <h2 class="title is-4 has-text-centered mt-3">SECURE</h2>
                <p>
                  SIMSED is uses best practice web security standards to ensure
                  safety and security of information stored or exchanged through
                  the application. Various security layers are implemented to
                  keep the school's data in a state of integrity.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- <section class="hero is-medium">
    <div class="hero-body">
      <div class="columns is-multiline">
        <div class="column is-12">
          <div class="columns is-mobile is-multiline is-centered">
            <div class="column is-10">
              <div class="columns is-multiline has-text-centered">
                <div class="column is-12">
                  <simsed-logo width="64" font-size="4em" />
                </div>
                <div class="column is-12">
                  <h2 class="subtitle">
                    School information management application
                  </h2>
                </div>
                <div class="column is-12">
                  <h3 class="subtitle is-6">
                    Are you searching for a simple and affordable
                    <br />school information management application for your
                    school?
                  </h3>
                </div>
                <div class="column is-12">
                  <div class="register-btn-container grow">
                    <router-link to="/register">
                      <p class="call-to-action">Try Simsed for 60 days</p>
                    </router-link>
                  </div>
                </div>
              </div>
            </div>

            <div class="column is-12 has-text-centered students">
              <img
                src="../assets/images/students.png"
                alt="students"
                width="500"
                height="auto"
              />
            </div>
            <div class="column is-6-mobile is-3-tablet has-text-centered">
              <h5 class="app-scopes">ACADEMICS</h5>
            </div>
            <div class="column is-6-mobile is-3-tablet has-text-centered">
              <h5 class="app-scopes">E-LEARNING</h5>
            </div>
            <div class="column is-6-mobile is-3-tablet has-text-centered">
              <h5 class="app-scopes">FINANCE</h5>
            </div>
            <div class="column is-6-mobile is-3-tablet has-text-centered">
              <h5 class="app-scopes">REPORTS</h5>
            </div>
          </div>
        </div>
        <div class="column is-12">
          <div class="columns is-multiline is-mobile">
            <div
              class="column is-12 has-text-centered mt-5 pt-5 building-blocks-header"
            >
              <h3>Simsed Building Blocks</h3>
              <h5>
                Simsed school application composition comprises: simplicity,
                functionality, scalability and security.
              </h5>
            </div>
            <div class="column is-12 building-blocks my-5 py-5">
              <div class="columns">
                <div class="column is-10 is-offset-1">
                  <div class="columns is-multiline">
                    <template v-for="(item, index) in appBuildingBlocks">
                      <div
                        class="column is-12-mobile is-1-tablet has-text-centered"
                        :key="index"
                      >
                        <i :class="`${item.icon} fa-5x`" />
                      </div>
                      <div
                        class="column is-12-mobile is-5-tablet has-text-left has-text-centered-mobile"
                        :key="item.title"
                      >
                        <h4 class="px-5">{{ item.title }}</h4>
                        <p class="px-5">{{ item.description }}</p>
                      </div>
                    </template>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="column is-6 is-offset-3">
          <figure class="image is-16by9">
            <iframe
              width="420"
              height="315"
              class="has-ratio"
              src="https://www.youtube.com/embed/usGFlTZddFw?rel=0"
              frameborder="0"
              allowfullscreen
            />
          </figure>
        </div>
        <div class="column is-12">
          <div class="columns is-multiline is-mobile">
            <div
              class="column is-12 has-text-centered mt-5 pt-5 why-choose-header"
            >
              <h3>Why choose Simsed?</h3>
              <h5>
                Simsed school application for management and administration is
                affordable, flexible and customizable.
              </h5>
            </div>
            <div class="column is-12 why-choose my-5 py-5">
              <div class="columns">
                <div class="column is-10 is-offset-1">
                  <div class="columns is-multiline">
                    <template v-for="(item, index) in appBenefits">
                      <div class="column is-6" :key="index">
                        <div class="columns is-multiline">
                          <div class="column is-12">
                            <span class="badge-circle-primary">
                              <h3>{{ index + 1 }}</h3>
                            </span>
                          </div>
                          <div class="column is-12 has-text-left">
                            <p class="px-5">{{ item.description }}</p>
                          </div>
                        </div>
                      </div>
                    </template>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="column is-12">
          <div class="register-btn-container grow">
            <router-link to="/register">
              <p class="call-to-action">Try Simsed for 60 days</p>
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </section> -->
</template>

<script>
import SimsedLogo from '../components/SimsedLogo'
export default {
  name: 'home',
  data() {
    return {
      appBuildingBlocks: [
        {
          title: 'Simple',
          description:
            'SIMSED is easy to use and without unnecessary complexities. The core of the application is built mainly on the most important processes of school operations. This simplification constrains expansion based on features needed by the school.',
          icon: 'far fa-smile',
        },
        {
          title: 'Functional',
          description:
            'SIMSED is designed to enable schools have an initial functional School Information Management Solution (SIMS) with a small footprint. This creates a flexible platform that is approachable for their operational needs and utilization.',
          icon: 'fas fa-palette',
        },
        {
          title: 'Scalable',
          description:
            "SIMSED is a Software-as-a-Service (SaaS) that is powered by Amazon Web Service's (AWS) industry proven cloud Infrastructure-as-a-Service (IaaS). The application is built to be scalable, stable and responsive under heavy workload.",
          icon: 'fas fa-cubes',
        },
        {
          title: 'Secure',
          description:
            "SIMSED uses best practice web security standards to ensure safety and security of information stored or exchanged through the application. Various security layers are implemented to keep the school's data in a state of integrity.",
          icon: 'fas fa-shield-alt',
        },
      ],
      appBenefits: [
        {
          description:
            "High adaptability with regards to your school's operation. Request for customizations to suite your operation.",
        },
        {
          description:
            'No complexities which creates cumbersome UX. Use a Simple, functional, scalable and secure application.',
        },
        {
          description:
            "Process admissions, keep track on attendance, streamline grading input and analyze student's progress. ",
        },
        {
          description:
            'Uses bulk publishing to prepare an entire classes report sheet for printing.',
        },
        {
          description:
            'Incooperates online payment of fees or bills, generates receipts and invoice.',
        },
        {
          description:
            'Cloud hosted for unified communication between administrators, teachers, students and parents.',
        },
      ],
    }
  },
  components: {
    SimsedLogo,
  },
}
</script>

<style lang="scss" scoped>
#main {
  margin-top: 74px;
}
.jumbo-app-name {
  font-family: $primary-font;
  font-size: 4em;
}

.jumbo-app-description {
  font-size: 1.5em;
}

.simsed-description {
  font-family: $secondary-font;
  font-size: 2.5em;
}

.simsed-building-blocks {
  font-family: $secondary-font;
  font-size: 2.5em;
}
.jumbo-parallax {
  width: 100%;
  height: 600px;
  background-image: url('../assets/images/classroom.webp');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-attachment: fixed;
  color: #fff;
}

.jumbo-overlay {
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 2;
  height: 100%;
  padding-top: 150px;
}

.jumbo-overlay h1 {
  font-size: 3em;
  text-align: center;
}

.jumbo-overlay h2 {
  font-size: 2em;
  text-align: center;
}

.jumbo-message {
  background-color: rgba(0, 0, 0, 0.6);
  color: rgb(255, 166, 0);
}
.register-btn-container {
  display: flex;
  width: 230px;
  justify-self: center;
  align-self: center;
  margin-left: auto;
  margin-right: auto;
  background-color: #23d160;
  justify-content: center;
}

.app-info h2 {
  font-family: $primary-font;
  color: $accent-color;
}

.app-info p {
  font-family: $secondary-font;
  font-size: 18px;
  margin-top: 24px;
  text-align: center;
  min-height: 150px;
}

.call-to-action {
  padding: 16px;
  color: #fff;
}

.app-scopes {
  font-family: $primary-font;
  font-size: 1.2em;
  color: $primary-dark;
}

// Building blocks
.building-blocks {
  background-color: $primary;
  min-height: auto;
}

.building-blocks-header h3 {
  font-family: $secondary-font;
  font-size: 2.5em;
}

.building-blocks-header h5 {
  font-family: $secondary-font;
}

.building-blocks i {
  color: $primary-text-color;
  font-size: 3.5em;
}

.building-blocks h4 {
  font-family: $primary-font;
  font-size: 2em;
  color: #ffffff;
}

.building-blocks p {
  font-family: $secondary-font;
  font-size: 1em;
  text-align: justify;
}

.why-choose {
  min-height: auto;
}

.why-choose-header h3 {
  font-family: $secondary-font;
  font-size: 2.5em;
  color: $primary-text-color;
}

.why-choose-header h5 {
  font-family: $secondary-font;
}

.why-choose p {
  font-family: $secondary-font;
  font-size: 1em;
  color: $primary-text-color;
  min-height: 120px;
  text-align: justify;
}

.badge-circle-primary {
  height: 70px;
  width: 70px;
  background-color: $primary-dark;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  opacity: 0.8;
  margin-left: auto;
  margin-right: auto;
  font-size: 1.8em;
}

.grow {
  transition: all 0.2s ease-in-out;
}
.grow:hover {
  transform: scale(1.1);
  background-color: $accent-color;
}
</style>
